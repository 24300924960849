import React, {lazy} from "react";
import { Container, Row, Col } from "react-bootstrap";

import SEO from "../components/seo";
import Image from "../components/widgets/basico/image";
//import Layout from "../components/layouts/layout";
import FormularioBasico from "../components/widgets/formularios/FormularioBasico";

const Layout = lazy(() => import('../components/layouts/layout'));

export default function Refacciones(props){

    const parameters = props.location.search;

    return(
        <Layout selectedMenuItem="refacciones" parameters={parameters}>
            <SEO
                title="Grupo Rivero - Refacciones Chevrolet Monterrey - Agencia Chevrolet"
                description="Refacciones Chevrolet Monterrey - Agencia Chevrolet refacciones - Refacciones GM - Refacciones Buick, Refacciones Chevrolet Originales - Autos Seminuevos Agencia"
                keywords="Taller Chevrolet, taller de servicio automotriz, seguros chevrolet, servicio chevrolet"
                path="/refacciones"
            />
            <Container>
                <div className="bg-white">
                    <Row>
                        <Col lg={8} md={8} sm={12}>
                            <Image fileName="rivero-refacciones-gm" alt="Refacciones"/>
                            <h1 className="p-4 bg-light">Refacciones</h1>
                            <div className="text-justify">
                                <div className="p-4">
                                    <p>
                                        En nuestro departamento de refacciones, contamos con el
                                        inventario más grande de partes, piezas y materiales
                                        originales de la línea <strong>General Motors</strong>, con
                                        catálogos de refacciones tanto para modelos de años
                                        recientes, así como de años anteriores.
                                    </p>
                                    <p>
                                        Contamos con refacciones para Chevrolet Aveo, Beat, Spark,
                                        Onix, Tornado, Cavalier, Trax, Tracker, Equinox, Silverado,
                                        Silverado 3500, Colorado, Cheyenne,Traverse, Blazer, Tahoe y
                                        Suburban 2021.
                                    </p>
                                    <p>
                                        Además de refacciones de unidades con cese de producción
                                        como el Chevrolet Cruze, Captiva, Luv Max, Avalanche y línea
                                        de lujo como Buick, GMC y Cadillac. Contamos además con
                                        todas las piezas disponibles para la línea de Chevrolet
                                        Camaro, Corvette y Chevrolet Bolt.
                                    </p>
                                    <p>
                                        Adicionalmente ofrecemos todos los productos en autopartes
                                        de la línea <strong>AC Delco</strong>, líder en calidad
                                        mundial de partes premium, para conservar tu unidad en
                                        excelentes condiciones. Desde aceites y alternadores,
                                        pasando por los amortiguadores, anticongelantes, baleros,
                                        bandas, baterías, bobinas, bombas de agua, bombas de
                                        gasolina, bujías, bujes, bulbos, cables para bujías,
                                        cilindros master de frenos, cilindros de freno, clutches,
                                        condensadores, cubre polvos, crucetas, discos de freno,
                                        filtros y flechas homocinéticas.
                                    </p>

                                    <h2>
                                        Encuentra todas las refacciones originales Chevrolet en
                                        Grupo Rivero{" "}
                                    </h2>

                                    <br />

                                    <h3>
                                        Somos un distribuidor autorizado de GM Parts y AC Delco.
                                    </h3>

                                    <p>
                                        <strong>Cualquier refacción de la línea de GM Parts</strong>{" "}
                                        puede ser solicitada y requerida bajo pedido, sin
                                        restricción de modelo o tipo de vehículo de la familia de
                                        GM, brindándote siempre los mejores tiempos de entrega de
                                        toda la zona norte de México.
                                    </p>

                                    <p>
                                        Recuerda siempre usar productos certificados de ACDelco y GM
                                        Parts para mantener la vigencia de tu garantía en tu
                                        vehículo Chevrolet o GM.
                                    </p>

                                    <p>
                                        ¿No contamos con la refacción que necesitas? Llena nuestro
                                        formulario de contacto para darle un seguimiento puntual de
                                        la pieza que te hace falta y resolverte a la brevedad. En
                                        Grupo Rivero te apoyamos de manera transparente en el
                                        cuidado y mantenimiento de tu auto.
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={4} sm={12} className="p-4">
                            <h3>Formulario de Contacto</h3>
                            <FormularioBasico pagina="refacciones" btnTitulo="Contactar" props={props}>
                                <input
                                    type="hidden"
                                    name="subject"
                                    id="subject"
                                    value="Refacciones"
                                    hidden
                                />
                            </FormularioBasico>
                        </Col>
                    </Row>         
                </div>
            </Container>
        </Layout>
    )

}